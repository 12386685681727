import { createContext } from "react";
import { useProposal } from "../useProposal";

const ProposalContext = createContext();

function ProposalProvider({ children }){
    const contextValues = useProposal();

    return (
        <ProposalContext.Provider value={contextValues}>
            { children }
        </ProposalContext.Provider>
    );
}

export { ProposalProvider, ProposalContext };

