import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ProposalProvider } from "./modules/proposal/hooks/context/proposalContext";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <ProposalProvider>
        <Switch>
          <Route
            path={`${APP_PREFIX_PATH}/dashboard`}
            component={lazy(() => import(`./modules/dashboard/components/dashboard`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/apps`}
            component={lazy(() => import(`./modules`))}
          />

          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
        </Switch>
      </ProposalProvider>
    </Suspense>
  );
};

export default React.memo(AppViews);
