import { Grid, Menu } from 'antd';
import navigationConfig from 'configs/NavigationConfig';
import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from 'constants/ThemeConstant';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { onMobileNavToggle } from 'redux/actions/Theme';
import utils from 'utils';
import Icon from '../util-components/Icon';
import IntlMessage from '../util-components/IntlMessage';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const [userRole, setUserRole] = useState('affiliate');
  useEffect(() => {
    const uRole = localStorage.getItem('user_role');
    if (uRole !== undefined) {
      setUserRole(uRole);
    }
  }, [localStorage.getItem('user_role')]);

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {navigationConfig.map((menu) =>
        !menu.restricted || menu.restricted.find((r) => r === userRole) ? (
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup
              key={menu.key}
              title={setLocale(localization, menu.title)}
            >
              {menu.submenu.map((subMenuFirst) =>
                !subMenuFirst.restricted ||
                subMenuFirst.restricted.find((r) => r === userRole) ? (
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map(
                        (subMenuSecond) =>
                          !subMenuSecond.restricted ||
                          subMenuSecond.restricted.find(
                            (r) => r === userRole
                          ) ? (
                            <Menu.Item key={subMenuSecond.key}>
                              {subMenuSecond.icon ? (
                                <Icon type={subMenuSecond?.icon} />
                              ) : null}
                              <span
                                style={
                                  subMenuSecond.needSpace
                                    ? { marginLeft: '0.7rem' }
                                    : {}
                                }
                              >
                                {setLocale(localization, subMenuSecond.title)}
                              </span>
                              <Link
                                onClick={() => closeMobileNav()}
                                to={subMenuSecond.path}
                              />
                            </Menu.Item>
                          ) : null

                        // {/* Adicionar mais um submenu */}
                        // {/* subMenuSecond.submenu.length > 0 ? (
                        //   {subMenuSecond.submenu.map((subMenuThird) => (
                        //     <Menu.Item key={subMenuThird.key}>
                        //       {subMenuThird.icon ? (
                        //         <Icon type={subMenuThird?.icon} />
                        //       ) : null}
                        //       <span style={subMenuThird.needSpace?{marginLeft: '0.7rem'}:{}}>
                        //         {setLocale(localization, subMenuThird.title)}
                        //       </span>
                        //       <Link onClick={() => closeMobileNav()} to={subMenuThird.path} />
                        //     </Menu.Item>
                        //   ))}
                        // : (
                        //   <Menu.Item key={subMenuSecond.key}>
                        //     {subMenuSecond.icon ? (
                        //       <Icon type={subMenuSecond?.icon} />
                        //     ) : null}
                        //     <span style={subMenuSecond.needSpace?{marginLeft: '0.7rem'}:{}}>
                        //       {setLocale(localization, subMenuSecond.title)}
                        //     </span>
                        //     <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                        //   </Menu.Item>
                        // ) */}
                      )}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? (
                        <Icon type={subMenuFirst.icon} />
                      ) : null}
                      <span
                        style={
                          subMenuFirst.needSpace ? { marginLeft: '0.7rem' } : {}
                        }
                      >
                        {setLocale(localization, subMenuFirst.title)}
                      </span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuFirst.path}
                      />
                    </Menu.Item>
                  )
                ) : null
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        ) : null
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span className="d-flex align-items-center">
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
