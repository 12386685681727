import { useEffect, useState } from 'react';
import api from 'configs/api';
import newApi from 'configs/new-api';

export function useProposal() {
  const [agreements, setAgreements] = useState([]);
  const [operations, setOperations] = useState([]);
  const [banks, setBanks] = useState([]);
  const [liberationFonts, setLiberationFonts] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(async () => {
    newApi
      .get('/agreement')
      .then(({ data }) => {
        setAgreements(data);
      })
      .catch((err) => console.log(err));

    newApi
      .get('/operation')
      .then(({ data }) => {
        setOperations(data);
      })
      .catch((err) => console.log(err));

    newApi
      .get('/bank')
      .then(({ data }) => {
        setBanks(data);
      })
      .catch((err) => console.log(err));

    newApi
      .get('/liberation-font')
      .then(({ data }) => {
        setLiberationFonts(data);
      })
      .catch((err) => console.log(err));

    newApi
      .get('/benefit')
      .then(({ data }) => {
        setBenefits(data);
      })
      .catch((err) => console.log(err));

    newApi
      .get(`/user/only-active`)
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [data, setData] = useState({
    proposal: {
      id: '',
      // The rest of fields
    },
    customer: {
      id: '',
      // Bank data
      bank: {},
      // The rest of fields
    },
    attachments: {
      notes: '',
      files: [],
    },
  });

  const resetDataToManage = () => {
    console.log('reseting');
    setDataToManage({
      proposal: {
        id: '',
      },
      customer: {
        id: '',
        bank: {},
      },
      attachments: {
        notes: '',
        files: [],
      },
    });
  };

  const setDataToManage = ({ customer, bank, proposal, attachments }) => {
    setData({
      proposal: {
        ...proposal,
      },
      customer: {
        ...customer,
        bank: {
          ...bank,
        },
      },
      attachments,
    });
  };

  return {
    dataToManage: data,
    setDataToManage,
    resetDataToManage,
    agreements,
    operations,
    banks,
    benefits,
    liberationFonts,
    users,
  };
}
