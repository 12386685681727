import { LogoutOutlined, ToolOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { Role } from 'models/role';
import { connect } from 'react-redux';
import { signOut } from 'redux/actions/Auth';
import { getPrincipal } from 'utils/UserUtils';

const menuItem = [
  {
    title: 'Configurações ',
    icon: ToolOutlined,
    path: '/app/apps/user/settings/change-password',
  },
];

const principal = getPrincipal();

export const NavProfile = ({ signOut, props }) => {
  const profileImg = '/img/avatars/perfil.svg';
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-1 pr-1">
            <h4 className="mb-0">Olá, {principal?.name}!</h4>
            <span className="text-muted">
              {Role[principal?.role] || 'Indefinido'}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={menuItem.length + 1} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sair</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
