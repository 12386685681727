import {
  DIR_LTR,
  NAV_TYPE_SIDE,
  SIDE_NAV_LIGHT,
} from "constants/ThemeConstant";
import { APIURL, NEWAPIURL } from "./EnvironmentConfig";

export const APP_NAME = "Raiz - Moura Finance";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const API_BASE_URL = APIURL;
export const NEW_API_BASE_URL = NEWAPIURL;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
