import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  RESET_PASSWORD,
  EXPIRATION_TOKEN,
  USER_ROLE,
  USER_ID,
  PRINCIPAL,
} from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
} from '../actions/Auth';

import FirebaseService from 'services/FirebaseService';
import api from 'configs/api';
import AuthService from 'services/AuthService';
import { removePrincipal } from 'utils/UserUtils';

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, function* ({ payload }) {
    const { email } = payload;
    try {
      const user = yield call(FirebaseService.sendPasswordResetEmail, email);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        localStorage.setItem('user', user.user);
        yield localStorage.setItem('user_email', user.user.email);
        yield api
          .get(`/usuarios/cargo/${user.user.uid}`)
          .then(({ data }) => localStorage.setItem('user_role', data));
        yield put(authenticated(user.user.uid));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const jwtResponse = yield call(AuthService.login, { email, password });

      console.log(jwtResponse);

      if (jwtResponse.message) {
        yield put(showAuthMessage(jwtResponse.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, jwtResponse.token);
        localStorage.setItem(EXPIRATION_TOKEN, jwtResponse.expiration);
        localStorage.setItem(USER_ROLE, jwtResponse.role);
        localStorage.setItem(USER_ID, jwtResponse.id);
        localStorage.setItem(PRINCIPAL, JSON.stringify(jwtResponse.principal));

        yield localStorage.setItem('user_email', email);

        yield put(authenticated(jwtResponse.token));
      }
    } catch (err) {
      yield put(
        showAuthMessage(
          err?.response?.data?.message ||
            'Ocorreu um erro, contate o administrador.'
        )
      );
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        removePrincipal();
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signUpEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield localStorage.setItem('user_email', user.user.email);
        yield api
          .get(`/usuarios/cargo/${user.user.uid}`)
          .then(({ data }) => localStorage.setItem('user_role', data));
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithFBEmail), fork(signOut), fork(signUpWithFBEmail)]);
}
