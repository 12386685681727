import React from "react";
import { APP_NAME } from "configs/AppConfig";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> Todos os
        Direitos Reservados
      </span>
      <div>
        <a className="text-gray" href="/#" onClick={(e) => e.preventDefault()}>
          Desenvolvido por{" "}
          <a href="https://kronustecnologia.com.br/" target={"_blank"}>
            Kronus Tecnologia
          </a>
        </a>
      </div>
    </footer>
  );
}
