import axios from "axios";
import { NEW_API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";

const newApi = axios.create({
  baseURL: NEW_API_BASE_URL,
});

newApi.interceptors.request.use(config => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default newApi;
