import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default api;
