export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const AUTH_TOKEN = 'auth_token';
export const EXPIRATION_TOKEN = 'expiration_token';
export const USER_ROLE = 'user_role';
export const USER_EMAIL = 'user_email';
export const USER_ID = 'user_id';
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE';
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED =
  'SIGNIN_WITH_GOOGLE_AUTHENTICATED';
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK';
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED =
  'SIGNIN_WITH_FACEBOOK_AUTHENTICATED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const PRINCIPAL = 'PRINCIPAL';
