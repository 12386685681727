import { message } from 'antd';
import fetch from 'auth/FetchInterceptor';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import newApi from 'configs/new-api';

const AuthService = {};

AuthService.login = function (data) {
  return newApi
    .post('/auth/login', data)
    .then((res) => {
      const jwtResponse = res.data;
      if (jwtResponse && jwtResponse.token) {
        return {
          token: jwtResponse.token,
          type: jwtResponse.type,
          expiration: new Date(jwtResponse.expiration),
          role: jwtResponse.principal?.role,
          id: jwtResponse.principal?.id,
          principal: jwtResponse.principal,
        };
      } else {
        throw new Error('Invalid JWT response');
      }
    })
    .catch((err) => {
      const response = err.response.data;

      if (response.status == 302) {
        message.warning('Senha expirada');
        window.location.href = `${AUTH_PREFIX_PATH}/reset-password/${data.email}`;
      }
      throw err;
    });
};

AuthService.resetPassword = function (data) {
  return newApi
    .post('/auth/reset-password', data)
    .then(() => {
      window.location.href = `${AUTH_PREFIX_PATH}/login`;
    })
    .catch((err) => {
      throw err;
    });
};

AuthService.signUp = function (data) {
  return fetch({
    url: '/auth/signup',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

export default AuthService;
